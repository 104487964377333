import * as React from "react";
import Layout from "../components/layout";
import BackLink from "../components/BackLink";
import {graphql} from "gatsby";
import BlockContent from "../components/BlockContent";

const DefaultPage = ({ data }) => {

  return (
    <Layout title={data.sanityPage.title}>
      <section className="max-w-screen-md mx-auto mb-12">
        <BackLink className={"mb-6"}/>
        <BlockContent blocks={data.sanityPage._rawContent}/>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    sanityPage(slug: {current: {eq: $slug}}) {
      title
      _rawContent(resolveReferences: {maxDepth: 5})
    }
  }
`

export default DefaultPage
