import {FaArrowLeft} from "react-icons/fa";
import * as React from "react";
import {navigate} from "gatsby";
import { useLocation } from "@reach/router";

const BackLink = ({ label = 'Back', className = '', to}) => {
  const location = useLocation();
  const navigateBack = (ev) => {
    ev.preventDefault();
    if (to) {
      navigate(to);
    } else if (window.history.length > 1 && location.state?.referrer) {
      navigate(-1)
    } else {
      navigate('/')
    }
  }
  return (
    <button onClick={navigateBack} className={`text-primary flex items-center gap-2 mt-8 font-semibold ${className}`}>
      <FaArrowLeft/>
      {label}
    </button>
  )
}

export default BackLink
